import React, { useEffect, useState } from "react";
import DefaultMarkdownPreview from "@uiw/react-markdown-preview";
import "./styles.scss";

const MarkdownPreview = ({ colorMode, text, customStyle }) => {
	return (
		<div data-color-mode={colorMode} className="text-2xl">
			<div className="wmde-markdown-var "> </div>
			<DefaultMarkdownPreview
				source={text}
				wrapperElement={{
					"data-color-mode": colorMode,
				}}
				className="!text-base text-left text-black dark:text-white"
				style={{
					// fontSize: "1.2rem",
					background: "transparent",
					...customStyle,
				}}
			/>
		</div>
	);
};
export default MarkdownPreview;
