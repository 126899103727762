import React, { useState } from "react";
import MDEditor from "@uiw/react-md-editor";
import { MdReportProblem } from "react-icons/md";

export default function AssistantChatLong({ message, avatar, flag }) {
	return (
		<div className="flex flex-col my-4 w-full">
			<div className="flex items-start">
				<img
					className="w-10 h-10 md:w-12 md:h-12 p-1 mr-2 rounded-full bg-black dark:bg-gray-200"
					src={avatar}
					alt="Assistant Avatar"
				/>
				<div
					className={
						`max-w-[80%] p-4 border-2 rounded-lg shadow-md bg-gray-800 ` +
						(flag ? `border-red-500` : `border-gray-700`)
					}
				>
					<MDEditor.Markdown
						className="text-xs"
						source={message.content}
						style={{
							background: "transparent",
							fontFamily: "Poppins",
							color: "white",
							textAlign: "left",
						}}
					/>
				</div>
			</div>
		</div>
	);
}
