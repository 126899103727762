import Chatter from "../../components/Chat/Chatter";
import {
	Box,
	Button,
	Card,
	Link,
	Skeleton,
	TextField,
	Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MarkdownEditor from "../../components/Markdown/MarkdownEditor";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { Save } from "@mui/icons-material";
import trainerApi from "../../api/trainer";
import { LoadingButton } from "@mui/lab";
import MarkdownPreview from "../../components/Markdown/MarkdownPreview";
import config from "../../config.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears } from "@fortawesome/free-solid-svg-icons";
import { showError, showSuccess } from "../../App";
import issueApi from "../../api/issue";
import Conversation from "./Conversation";

export default function PreviewIssue() {
	const [id, setId] = useState(null);
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const [response, setResponse] = useState(null);
	const [value, setValue] = useState({
		question: "",
		answer: "",
		url: "",
	});
	const [data, setData] = useState(null);
	const theme = useTheme();

	const fetchData = async () => {
		const res = await issueApi.getIssueById(id);
		if (res.success) {
			setResponse(res.data.response);
			setData(res.data);
			setValue({
				question: "",
				answer: res.data.response,
				url: "",
			});
			console.log(res.data);
		} else {
			console.log("Error:", res.error);
			// navigate("/trainer");
		}
	};

	const saveResponse = async () => {
		setLoading(true);
		const res = await issueApi.resolveIssue(id, value.answer);
		if (res.success) {
			showSuccess("Response saved successfully");
			setLoading(false);
		} else {
			showError("Error saving response");
			console.log("Error:", res.error);
			setLoading(false);
		}
	};
	useEffect(() => {
		setId(location.hash.substring(1));
	}, [location]);
	useEffect(() => {
		if (id) {
			fetchData();
		}
	}, [id]);
	return (
		<Box
			className="p-5 mx-auto flex items-center flex-col gap-10 w-full"
			sx={{
				minHeight: `calc(100vh - ${config.topbarHeight}px)`,
			}}
		>
			{data && (
				<Conversation
					chat={data.chat}
					message_id={data.message_id}
					feedback={data.feedback}
				/>
			)}

			<div className="w-full md:w-[60vw] flex-grow flex items-center mx-auto">
				<Card className="p-5 flex flex-col gap-5 rounded-lg w-full">
					{/* <div>
						<Typography
							align="left"
							className="py-2 text-lg font-semibold"
						>
							Question *
						</Typography>
						<TextField
							variant="outlined"
							required
							// label="Question"
							type="text"
							fullWidth
							multiline
							value={value.question}
							onChange={(e) =>
								setValue({ ...value, question: e.target.value })
							}
						/>
					</div> */}
					<div className="">
						<Typography
							align="left"
							className="py-2 text-lg font-semibold"
							// style={{
							// 	borderColor: theme.palette.divider,
							// 	backgroundColor: theme.palette.background.default,
							// }}
						>
							Response
						</Typography>
						<MarkdownEditor
							text={value.answer}
							colorMode={theme.palette.mode}
							setText={(value) =>
								setValue((prev) => ({ ...prev, answer: value }))
							}
						/>
					</div>

					{/* <div>
						<Typography
							align="left"
							className="py-2 text-lg font-semibold"
						>
							URL
						</Typography>
						<TextField
							variant="outlined"
							required
							// label="Url"
							type="text"
							fullWidth
							value={value.url}
							onChange={(e) =>
								setValue({ ...value, url: e.target.value })
							}
						/>
					</div> */}

					<LoadingButton
						size="large"
						className="flex ml-auto flex-row gap-1 items-center min-w-full md:min-w-16"
						variant="contained"
						color="primary"
						onClick={saveResponse}
						loading={loading}
						// loadingPosition="start"
					>
						<Save />
						<Typography className="font-semibold">Save</Typography>
					</LoadingButton>
				</Card>
			</div>
		</Box>
	);
}
