import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MarkdownEditor from "../../components/Markdown/MarkdownEditor";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { Card, TextField, Typography } from "@mui/material";
import { Add, Save } from "@mui/icons-material";
import trainerApi from "../../api/trainer";
import { LoadingButton } from "@mui/lab";
import config from "../../config.json";
export default function QuestionForm({
	value,
	setValue,
	onClick,
	title,
	loading,
}) {
	const theme = useTheme();
	return (
		<div
			className="p-5 w-full md:w-[60vw] flex-grow flex items-center mx-auto"
			style={{
				minHeight: `calc(100vh - ${config.topbarHeight}px)`,
			}}
		>
			<Card className="p-5 flex flex-col gap-3 rounded-lg w-full">
				<h1 className="text-3xl font-bold">{title}</h1>
				<div>
					<Typography
						align="left"
						className="py-2 text-lg font-semibold"
					>
						Question *
					</Typography>
					<TextField
						variant="outlined"
						required
						// label="Question"
						type="text"
						fullWidth
						multiline
						value={value.question}
						onChange={(e) =>
							setValue({ ...value, question: e.target.value })
						}
					/>
				</div>

				<div className="">
					<Typography
						align="left"
						className="py-2 text-lg font-semibold"
						// style={{
						// 	borderColor: theme.palette.divider,
						// 	backgroundColor: theme.palette.background.default,
						// }}
					>
						Answer *
					</Typography>
					<MarkdownEditor
						text={value.answer}
						colorMode={theme.palette.mode}
						setText={(value) =>
							setValue((prev) => ({ ...prev, answer: value }))
						}
					/>
				</div>

				{/* <TextField
				variant="outlined"
				required
				label="Answer"
				type="text"
				fullWidth
				multiline
				value={value.answer}
				onChange={(e) => setValue({ ...value, answer: e.target.value })}
			/> */}
				<div>
					<Typography
						align="left"
						className="py-2 text-lg font-semibold"
					>
						URL
					</Typography>
					<TextField
						variant="outlined"
						required
						// label="Url"
						type="text"
						fullWidth
						value={value.url}
						onChange={(e) =>
							setValue({ ...value, url: e.target.value })
						}
					/>
				</div>
				<LoadingButton
					size="large"
					className="flex ml-auto flex-row gap-1 items-center min-w-full md:min-w-16"
					variant="contained"
					color="primary"
					onClick={onClick}
					loading={loading}
					// loadingPosition="start"
				>
					<Save />
					<Typography className="font-semibold">Save</Typography>
				</LoadingButton>
			</Card>
		</div>
	);
}
