import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import QuestionTable from "./QuestionTable";
import AddButton from "../../components/Buttons/AddButton";

export default function AdminTrainer() {
	const navigate = useNavigate();
	return (
		<div className="p-5 mx-auto flex items-center flex-col justify-center w-full">
			<div className="md:hidden mb-4 w-full">
				<Button
					fullWidth
					variant="contained"
					color="primary"
					startIcon={<Add sx={{ fontSize: "2rem" }} />}
					size="large"
					className="text-lg"
					onClick={() => navigate("/trainer/add")}
				>
					Add Question
				</Button>
			</div>
			<QuestionTable />
			<div className="hidden md:block">
				<AddButton onClick={() => navigate("/trainer/add")} />
			</div>
		</div>
	);
}
