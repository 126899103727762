import React from "react";
import MDEditor from "@uiw/react-md-editor";
import { Typography } from "@mui/material";
export default function UserChatLong({ message, avatar, flag }) {
	return (
		<div className="flex w-full justify-end my-2">
			<div
				href="#"
				class={`max-w-[80%]  p-4 border-2 text-white border rounded-lg shadow-md bg-gray-800 ${
					flag ? "border-red-500" : "border-primary-400"
				}`}
			>
				<MDEditor.Markdown
					className="text-xs"
					source={message.content}
					style={{
						background: "transparent",
						color: "white",
						fontFamily: "Poppins",
						textAlign: "right",
					}}
				/>
			</div>
			<img
				className="w-10 h-10 md:w-12 md:h-12 p-1 ml-2 rounded-full bg-black dark:bg-gray-200"
				src={avatar}
				alt=""
			/>
		</div>
	);
}
