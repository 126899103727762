import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import IssueTable from "./IssueTable";
import AddButton from "../../components/Buttons/AddButton";

export default function OpenIssues() {
	const navigate = useNavigate();
	return (
		<div className="p-5 mx-auto flex items-center flex-col justify-center w-full">
			<IssueTable />
		</div>
	);
}
