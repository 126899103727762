import logo from "./logo.svg";
import "./App.css";
import Login from "./pages/Login";
import { useContext, useEffect, useState } from "react";
import { AppBar, CssBaseline, Toolbar, createTheme } from "@mui/material";
import { ThemeProvider, useTheme } from "@emotion/react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	Outlet,
} from "react-router-dom";
import { ThemeContext } from "./contexts/ThemeContextProvider";
import ToggleMode from "./components/Buttons/ToggleButton";
import PrimaryLayout from "./components/Layouts/PrimaryLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBoxTissue,
	faChartLine,
	faPen,
	faRobot,
	faUser,
	faUsers,
} from "@fortawesome/free-solid-svg-icons";
import LeftSidebar from "./components/Sidebars/LeftSidebar";
import Trainer from "./pages/Trainer";
import AddQuestion from "./pages/Trainer/AddQuestion";
import EditQuestion from "./pages/Trainer/EditQuestion";
import PreviewQuestion from "./pages/Trainer/PreviewQuestion";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Scraper from "./pages/Scraper";
import OpenIssues from "./pages/Issues";
import config from "./config";
import PreviewIssue from "./pages/Issues/PreviewIssue";
import Home from "./pages/Home";
import { getTokenFromLocalStorage, isTokenValid } from "./api/base";
import UserManagement from "./pages/UserManagement";
export const showToast = (message, type) => {
	console.log(message, type);
	if (type === "success") toast.success(message, {});
	else if (type === "error") toast.error(message, {});
	else {
		toast.dark(message, {});
	}
};
export const showSuccess = (message) => {
	showToast(message, "success");
};
export const showError = (message) => {
	showToast(message, "error");
};
export const showMessage = (message, res) => {
	showToast(message);
};

const Protected = () => {
	const isLoggedIn = isTokenValid();

	// const [isLoggedIn, setIsLoggedIn] = useState(false);
	// const handleStorageChange = () => {
	// 	const token = localStorage.getItem("token");
	// 	if (token) {
	// 		setIsLoggedIn(true);
	// 	} else {
	// 		setIsLoggedIn(false);
	// 	}
	// };

	// useEffect(() => {
	// 	const token = localStorage.getItem("token");
	// 	console.log(token);
	// 	if (token) {
	// 		setIsLoggedIn(true);
	// 	}
	// 	window.addEventListener("storage", handleStorageChange);
	// 	return () => {
	// 		window.removeEventListener("storage", handleStorageChange);
	// 	};
	// }, []);
	return isLoggedIn ? (
		<PrimaryLayout>
			<Outlet />
		</PrimaryLayout>
	) : (
		<Navigate to={"/login"} />
	);
};

const Public = () => {
	const isLoggedIn = isTokenValid();
	return isLoggedIn ? <Navigate to={"/trainer"} /> : <Outlet />;
};

function App() {
	return (
		<>
			<Router>
				<div className="App h-full">
					<Toolbar className="fixed right-0 top-0 ml-auto flex flex-row justify-end p-3 items-center gap-4 z-100">
						<ToggleMode />
					</Toolbar>

					<div className="content">
						<Routes>
							<Route element={<Public />}>
								<Route path="/login" element={<Login />} />
							</Route>
							<Route element={<Protected />}>
								<Route path="/" element={<Home />} />
								<Route
									path="/analytics"
									element={
										<div className="flex flex-col items-center justify-center w-full h-full"></div>
									}
								/>
								<Route path="/scraper" element={<Scraper />} />

								<Route path="/trainer" element={<Trainer />} />
								<Route
									path="/trainer/add"
									element={<AddQuestion />}
								/>
								<Route
									path="/trainer/preview"
									element={<PreviewQuestion />}
								/>
								<Route
									path="/trainer/edit"
									element={<EditQuestion />}
								/>
								<Route
									path="/issues"
									element={<OpenIssues />}
								/>
								<Route
									path="/issues/preview"
									element={<PreviewIssue />}
								/>
								<Route
									path="/users"
									element={<UserManagement />}
								/>
							</Route>
							<Route
								path="*"
								element={
									<Navigate
										replace
										to={config.loginRedirect}
									/>
								}
							/>
						</Routes>
					</div>
				</div>
			</Router>
			<ToastContainer
				style={{ width: "270px" }}
				position="top-right"
				theme="colored"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={true}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
}

export default App;
