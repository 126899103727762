import { Button, Card, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MarkdownEditor from "../../components/Markdown/MarkdownEditor";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import QuestionForm from "./QuestionForm";
import { Add, Save } from "@mui/icons-material";
import trainerApi from "../../api/trainer";
import { LoadingButton } from "@mui/lab";
import { showError } from "../../App";

export default function AddQuestion() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		question: "",
		answer: "",
		url: "",
	});

	const handleAdd = async () => {
		// add question
		setLoading(true);
		if (data.question === "" || data.answer === "") {
			showError("Question or Answer cannot be empty");
			setLoading(false);
			return;
		}
		const res = await trainerApi.createQuestion(data);
		if (res.success) {
			console.log("Added:", data);
			navigate("/trainer");
		} else {
			console.log("Error:", res.error);
			setLoading(false);
		}
	};
	return (
		<QuestionForm
			value={data}
			setValue={setData}
			title={"New Question"}
			onClick={handleAdd}
			loading={loading}
		/>
	);
}
