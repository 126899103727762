import { useEffect, useState } from "react";
import scraperApi from "../../api/scraper";
import { showError, showSuccess } from "../../App";
import {
	Autocomplete,
	Box,
	Button,
	Card,
	CardHeader,
	Divider,
	FormControl,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { Refresh } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthAmericas } from "@fortawesome/free-solid-svg-icons";
import config from "../../config";
import { LoadingButton } from "@mui/lab";
import userApi from "../../api/user";
import Conversation from "./Conversation";
export default function UserManagement() {
	const [users, setUsers] = useState([]);
	const [user, setUser] = useState("");
	const [chats, setChats] = useState([]);
	const [chat, setChat] = useState("");
	const [conversation, setConversation] = useState(null);

	const [data, setData] = useState();
	const [loadingUsers, setLoadingUsers] = useState(false);
	const [loadingChats, setLoadingChats] = useState(false);

	const fetchChats = async () => {
		setLoadingUsers(true);
		const res = await userApi.getChatsByUser(user);
		if (res.success) {
			setChats(res.data);
		}
		setLoadingUsers(false);
	};
	const fetchUsers = async () => {
		const res = await userApi.getAllUsers();
		if (res.success) {
			setUsers(res.data);
		}
	};

	const fetchConversation = async () => {
		setLoadingChats(true);
		const res = await userApi.getChatById(chat);
		if (res.success) {
			setConversation(res.data);
			console.log(res.data);
		}
		setLoadingChats(false);
	};

	useEffect(() => {
		if (user !== "") {
			fetchChats();
		}
		setChats([]);
	}, [user]);

	useEffect(() => {
		setChat("");
	}, [chats]);

	useEffect(() => {
		if (chat !== "") fetchConversation();
		setConversation(null);
	}, [chat]);
	useEffect(() => {
		fetchUsers();
	}, []);
	return (
		<Box className="p-5 mx-auto flex items-center flex-col gap-10 justify-center w-full">
			<Card className="w-full max-w-4xl p-3 flex flex-col gap-3">
				{/* <Typography variant="h5">Dedicated Scrapers</Typography>
				<Divider /> */}
				<div className="flex flex-row gap-5">
					<Box className="w-full">
						<FormControl fullWidth>
							<Select
								labelId="scraper-select-label"
								id="scraper-select"
								value={user}
								onChange={(e) => {
									setUser(e.target.value);
								}}
								displayEmpty
								size="small"
							>
								<MenuItem value="">
									<em>Select a user</em>
								</MenuItem>
								{users.map((u) => (
									<MenuItem key={u.id} value={u.id}>
										{u.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
					{/* <LoadingButton
						variant="contained"
						color="primary"
						className="w-1/3"
						onClick={fetchChats}
						// startIcon={<Refresh />}
						disabled={!user}
						loading={loadingUsers}
						// loadingPosition="start"
					>
						Find Chats
					</LoadingButton> */}
				</div>

				{chats.length > 0 && (
					<div className="flex flex-row gap-5">
						<Box className="w-full">
							<FormControl fullWidth>
								<Select
									labelId="scraper-select-label"
									id="scraper-select"
									value={chat}
									onChange={(e) => setChat(e.target.value)}
									displayEmpty
									size="small"
								>
									<MenuItem value="">
										<em>Select a chat</em>
									</MenuItem>
									{chats.map((chat) => (
										<MenuItem key={chat.id} value={chat.id}>
											{chat.first_message}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
						{/* <LoadingButton
						variant="contained"
						color="primary"
						className="w-1/3"
						onClick={fetchConversation}
						// startIcon={<Refresh />}
						disabled={!chat}
						loading={loadingChats}
						// loadingPosition="start"
					>
						See Conversation
					</LoadingButton> */}
					</div>
				)}
			</Card>
			{conversation && <Conversation chat={conversation} />}
		</Box>
	);
}
