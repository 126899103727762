import {
	Button,
	Card,
	Link,
	Skeleton,
	TextField,
	Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MarkdownEditor from "../../components/Markdown/MarkdownEditor";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import QuestionForm from "./QuestionForm";
import { Save } from "@mui/icons-material";
import trainerApi from "../../api/trainer";
import { LoadingButton } from "@mui/lab";
import MarkdownPreview from "../../components/Markdown/MarkdownPreview";
import config from "../../config.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears } from "@fortawesome/free-solid-svg-icons";
import { showError, showSuccess } from "../../App";

export default function PreviewQuestion() {
	const navigate = useNavigate();
	const theme = useTheme();
	const [loading, setLoading] = useState(true);
	const [buttonLoading, setButtonLoading] = useState(false);
	const location = useLocation();
	const [id, setId] = useState(null);
	const [data, setData] = useState(null);
	const [isTrainable, setIsTrainable] = useState(false);
	useEffect(() => {
		setId(location.hash.substring(1));
	}, [location]);

	const fetchData = async () => {
		const res = await trainerApi.getQuestionById(id);
		if (res.success) {
			setData(res.data);
			console.log(res.data);
			setIsTrainable(
				res.data.last_trained
					? true
					: res.data.last_trained !== res.data.updated_at
			);
			setLoading(false);
		} else {
			console.log("Error:", res.error);
			// navigate("/trainer");
		}
	};

	const handleTrain = async () => {
		if (data.question === "" || data.answer === "") {
			showError("Question or Answer is empty");
			return;
		}
		setButtonLoading(true);
		const res = await trainerApi.trainQuestion(data.id);
		if (res.success) {
			showSuccess("Trained successfully");
			setIsTrainable(false);
		} else {
			showError("Can't train the question. Please try again.");
		}
		setButtonLoading(false);
	};

	useEffect(() => {
		if (id) {
			fetchData();
		}
	}, [id]);

	return (
		<div
			className="p-5 w-full md:w-[60vw] flex flex-col items-center justify-center mx-auto"
			style={{
				minHeight: `calc(100vh - ${config.topbarHeight}px)`,
			}}
		>
			<Card className="p-5 flex flex-col gap-3 rounded-lg w-full">
				<div>
					<Typography
						align="left"
						className="py-2 text-xl font-semibold"
					>
						Question
					</Typography>
					<Typography fullWidth align="left" className="text-base">
						{loading ? (
							<div className="w-full">
								<Skeleton
									variant="text"
									width="100%"
									height={30}
								/>
								<Skeleton
									variant="text"
									width={"40%"}
									height={30}
									className="md:hidden"
								/>
							</div>
						) : (
							data.question
						)}
					</Typography>
				</div>

				<div className="">
					<Typography
						align="left"
						className="py-2 text-xl font-semibold"
					>
						Answer
					</Typography>
					{loading ? (
						<div className="flex flex-col w-full gap-2">
							<div className="flex flex-col w-full">
								<Skeleton
									variant="text"
									width="100%"
									height={30}
								/>
								<Skeleton
									variant="text"
									width="100%"
									height={30}
								/>
								<Skeleton
									variant="text"
									width="100%"
									height={30}
								/>
								<Skeleton
									variant="text"
									width="40%"
									height={30}
								/>
							</div>
							<div className="flex flex-col w-full">
								<Skeleton
									variant="text"
									width="100%"
									height={30}
								/>
								<Skeleton
									variant="text"
									width="90%"
									height={30}
								/>
								<Skeleton
									variant="text"
									width="100%"
									height={30}
								/>
								<Skeleton
									variant="text"
									width="60%"
									height={30}
								/>
							</div>
						</div>
					) : (
						<MarkdownPreview
							text={data.answer}
							colorMode={theme.palette.mode}
						/>
					)}
				</div>

				{(!data || data.url) && (
					<div className="flex flex-row gap-2 items-center">
						<Typography
							align="left"
							className="py-2 text-xl font-semibold"
						>
							Reference:
						</Typography>

						{loading ? (
							<div className="w-full">
								<Skeleton
									variant="text"
									width="80%"
									height={30}
								/>
							</div>
						) : (
							<Link
								href={data.url}
								variant="body2"
								className="mr-auto text-base"
							>
								{data.url}
							</Link>
						)}
					</div>
				)}
			</Card>
			{/* <div className="md:hidden mt-4 w-full">
				<LoadingButton
					fullWidth
					variant="contained"
					color="primary"
					startIcon={
						<FontAwesomeIcon
							icon={faGears}
							style={{ fontSize: "1.2rem" }}
						/>
					}
					size="large"
					className="text-lg"
					onClick={handleTrain}
					loading={buttonLoading}
					loadingPosition="start"
					disabled={!isTrainable}
				>
					Train
				</LoadingButton>
			</div>
			<div className="hidden md:block">
				<div className="fixed bottom-10 z-10 right-10 items-center justify-center ">
					<LoadingButton
						onClick={handleTrain}
						variant="contained"
						color="primary"
						className="w-16 h-16 rounded-full justify-center inline-flex items-center p-4"
						loading={buttonLoading}
						disabled={!isTrainable}
					>
						<FontAwesomeIcon
							icon={faGears}
							style={{ fontSize: "2rem" }}
						/>
					</LoadingButton>
				</div>
			</div> */}
		</div>
	);
}
