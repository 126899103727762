import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
	Button,
	Card,
	CardActionArea,
	CardContent,
	Skeleton,
	TableHead,
	Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEye,
	faPenToSquare,
	faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { Add, Delete, Edit, Preview } from "@mui/icons-material";
import Confirmation from "../../components/Cards/Confirmation";
import CustomTablePaginationActions from "../../components/Tables/TablePaginationActions";
import trainerApi from "../../api/trainer";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { showError, showSuccess } from "../../App";
import issueApi from "../../api/issue";
import { issue } from "@uiw/react-md-editor";
import { format } from "date-fns";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));
function createData(id, issued_by, created_at, message_content) {
	return { id, issued_by, created_at: new Date(created_at), message_content };
}
export default function IssueTable() {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [rows, setRows] = useState([]);
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();
	const [selected, setSelected] = useState({});
	const [data, setData] = useState();
	const [loading, setLoading] = useState(true);
	// State to store random widths
	const [randomWidths, setRandomWidths] = useState([]);

	useEffect(() => {
		// Generate 5 random widths when the component mounts
		const widths = Array.from(
			{ length: 5 },
			() => `${Math.floor(Math.random() * (90 - 10 + 1)) + 10}%`
		);
		setRandomWidths(widths);
	}, []);

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		if (!data) return;
		setRows(
			data
				.map((item) =>
					createData(
						item.id,
						item.issued_by,
						item.created_at,
						item.message_content
					)
				)
				.sort((a, b) => b.created_at - a.created_at)
		);
		// sleep for 1 second to show the skeleton
		setLoading(false);
	}, [data]);

	const fetchData = async () => {
		const response = await issueApi.getIssues();
		if (response.success) {
			console.log(response.data);
			setData(response.data);
		} else {
			// showError("Failed to fetch data.");
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleDelete = async (row) => {
		const res = await trainerApi.deleteQuestionById(row.id);
		if (res.success) {
			// console.log("Deleted:", row);
			showSuccess("Successfully deleted.");
		} else {
			// Revert the changes
			setData((prev) => [...prev, row]);
			showError("Failed to delete.");
		}
	};
	return (
		<div className="w-full max-w-4xl">
			<Confirmation
				open={open}
				setOpen={setOpen}
				onConfirm={() => {
					// console.log("Deleting ", selected);
					const newData = data.filter(
						(item) => item.id !== selected.id
					);
					setData(newData);
					handleDelete(selected);
					setSelected(null);
				}}
			/>
			{loading ? (
				<TableContainer component={Paper}>
					<Table aria-label="custom pagination table">
						<TableHead>
							<TableRow>
								<TableCell className="!py-3">
									<div className="flex flex-row gap-4 justify-end">
										<Skeleton
											variant="text"
											height={30}
											className="w-40"
										/>
										<Skeleton
											variant="text"
											height={30}
											className="w-24"
										/>
										<Skeleton
											variant="text"
											// width={"10rem"}
											height={30}
											className="w-12 md:w-32"
										/>
									</div>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{randomWidths.map((rWidth, index) => (
								<StyledTableRow
									key={index}
									className="font-sans text-base"
								>
									<TableCell className="min-w-[1rem] font-sans text-base">
										<div className="flex flex-col md:flex-row gap-2">
											<div
												className="w-full flex flex-col"
												style={{
													width: {
														md: `calc(100% - 5rem)`,
													},
												}}
											>
												<Skeleton
													variant="text"
													width="100%"
													height={30}
												/>
												<Skeleton
													variant="text"
													width={rWidth}
													height={30}
													// className="md:hidden"
												/>
											</div>

											<div className="flex flex-row w-[5rem] ml-auto z-10 gap-2">
												<Skeleton
													variant="circular"
													width={30}
													height={30}
												/>
												{/* <Skeleton
													variant="circular"
													width={30}
													height={30}
													style={{ marginLeft: 8 }}
												/> */}
												<Skeleton
													variant="circular"
													width={30}
													height={30}
													// style={{ marginLeft: 8 }}
												/>
											</div>
										</div>
									</TableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<TableContainer component={Paper}>
					<Table aria-label="custom pagination table">
						{/* <TableHead>
							<TableRow>
								<StyledTableCell>
									<div
										className="flex flex-row"
										style={{
											width: `calc(100% - 6rem)`,
										}}
									>
										<Typography
											sx={{
												width: `calc(60%)`,
											}}
											align="left"
										>
											Issued By
										</Typography>
										<Typography
											sx={{
												width: `calc(40%)`,
											}}
											align="left"
										>
											Created At
										</Typography>
									</div>
								</StyledTableCell>
							</TableRow>
						</TableHead> */}
						<TableBody>
							{(rowsPerPage > 0
								? data.slice(
										page * rowsPerPage,
										page * rowsPerPage + rowsPerPage
								  )
								: data
							).map((row) => (
								<StyledTableRow
									key={row.name}
									className="font-sans text-base"
								>
									<TableCell className="min-w-[1rem] font-sans text-base">
										<div className="flex flex-col md:flex-row gap-2">
											<div
												style={{
													width: `calc(100% - 6rem)`,
												}}
												className="flex flex-row"
											>
												<div
													className="w-full"
													// style={{
													// 	width: `calc(60%)`,
													// }}
												>
													<Typography>
														{row.message_content}
													</Typography>
												</div>
												{/* <div
													className="w-full"
													style={{
														width: `calc(40%)`,
													}}
												>
													<Typography>
														{format(
															row.created_at,
															"PPpp"
														)}
													</Typography>
												</div> */}
											</div>

											<div className="flex flex-row w-[6rem] ml-auto z-10">
												<div className="w-1/2">
													<IconButton
														onClick={() =>
															navigate(
																"/issues/preview#" +
																	row.id
															)
														}
													>
														<FontAwesomeIcon
															icon={faEye}
														/>
													</IconButton>
												</div>

												<div className="w-1/2">
													<IconButton
														onClick={() => {
															setSelected(row);
															setOpen(true);
														}}
													>
														<Delete />
													</IconButton>
												</div>
											</div>
										</div>
									</TableCell>
								</StyledTableRow>
							))}
						</TableBody>
						<TableHead>
							<TableRow>
								<TablePagination
									rowsPerPageOptions={[
										5,
										10,
										25,
										{ label: "All", value: -1 },
									]}
									colSpan={3}
									count={rows.length}
									rowsPerPage={rowsPerPage}
									page={page}
									slotProps={{
										select: {
											inputProps: {
												"aria-label": "rows per page",
											},
											native: true,
										},
									}}
									onPageChange={handleChangePage}
									onRowsPerPageChange={
										handleChangeRowsPerPage
									}
									ActionsComponent={
										CustomTablePaginationActions
									}
								/>
							</TableRow>
						</TableHead>
					</Table>
				</TableContainer>
			)}
		</div>
	);
}
