import React, { useContext, useState, useEffect } from "react";
// import AuthService from "../services/authService";
import {
	FormControl,
	InputLabel,
	OutlinedInput,
	Button,
	Box,
	TextField,
	Typography,
	Container,
	CssBaseline,
	Card,
	CardContent,
	InputAdornment,
	Avatar,
	Divider,
} from "@mui/material";
import packageInfo from "../../package.json"; // Adjust the path as necessary

import {
	Google as GoogleIcon,
	Facebook as FacebookIcon,
	LoginOutlined,
} from "@mui/icons-material"; // Import icons
import EyeIcon from "../components/Icons/EyeIcon";
import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import authService from "../services/auth";
// import { setLoading } from "./page";
import config from "../config";

const MuiTextField = (props) => {
	return (
		<div className="no-ring-input">
			<FormControl fullWidth variant="outlined" size="small">
				<InputLabel htmlFor="outlined-adornment">
					{props.label}
				</InputLabel>
				<OutlinedInput
					required
					placeholder={props.placeholder}
					id="outlined-adornment"
					className="outlined-input"
					type="text"
					value={props.value}
					onChange={props.onChange}
					label={props.label}
					size="small"
					fullWidth
				/>
			</FormControl>
		</div>
	);
};

const MuiPasswordField = (props) => {
	const [showPassword, setShowPassword] = useState(false);
	return (
		<FormControl fullWidth variant="outlined" size="small" required>
			<InputLabel htmlFor="outlined-adornment">Password</InputLabel>
			<OutlinedInput
				placeholder="••••••••"
				type={showPassword ? "text" : "password"}
				value={props.value}
				onChange={props.onChange}
				label="Password"
				size="small"
				fullWidth
				endAdornment={
					<EyeIcon
						isVisible={props.value.length > 0}
						showPassword={showPassword}
						setShowPassword={setShowPassword}
					/>
				}
			/>
		</FormControl>
	);
};
const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loggingIn, setLoggingIn] = useState(false);
	const theme = useTheme();
	const navigate = useNavigate();
	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoggingIn(true);
		const res = await authService.login({
			email,
			password,
		});
		if (res.success) {
			// do some delay
			setLoggingIn(false);
			navigate(config.loginRedirect);
		} else {
			setLoggingIn(false);
		}
	};

	useEffect(() => {
		// setLoading(false);
	}, []);
	return (
		<Container
			component="main"
			maxWidth="xs"
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
			}}
		>
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "100%",
				}}
			>
				{/* <Avatar sx={{ m: 1, bgcolor: "white", width: 70, height: 70 }}>
					<img
						width={200}
						height={50}
						src="/images/logo.png"
						alt=""
					/>
				</Avatar> */}
				<div
					// onClick={() => {
					// 	// setLoading(true);
					// 	navigate("/");
					// }}
					className="absolute cursor-pointer"
					style={{ transform: "translateY(-150%)" }} // Move up by 20px
				>
					<div className="text-3xl font-bold font-logo text-[#444] dark:text-white">
						<FontAwesomeIcon icon={faRobot} /> Penguin Admin
					</div>
				</div>

				<Card
					sx={{ maxWidth: 600, width: "100%" }}
					className="rounded-lg"
				>
					<CardContent
						className="flex flex-col gap-3 !p-10"
						sx={{ paddingBottom: "3rem" }}
					>
						<Typography
							variant="h5"
							component="h2"
							className="font-semibold"
						>
							Sign in to your account
						</Typography>
						<form
							className="flex flex-col gap-4"
							onSubmit={handleSubmit}
						>
							<TextField
								label="Email"
								type="email"
								fullWidth
								required
								// margin="normal"
								variant="outlined"
								size="small"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							<MuiPasswordField
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<LoadingButton
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								loading={loggingIn}
								// className="font-semibold"
								// loadingPosition="end"
								// endIcon={<LoginOutlined />}
							>
								Sign In
							</LoadingButton>

							{/* <Divider sx={{ my: 0 }}>
								<Typography
									variant="body2"
									color="textSecondary"
								>
									or
								</Typography>
							</Divider> */}

							{/* <Box display="flex" flexDirection="column" gap={2}>
								<Button
									variant="outlined"
									startIcon={<GoogleIcon />}
									fullWidth
									onClick={() => console.log("Google login")} // Replace with your actual login function
								>
									Continue with Google
								</Button>
								<Button
									variant="outlined"
									startIcon={<FacebookIcon />}
									fullWidth
									onClick={() =>
										console.log("Facebook login")
									} // Replace with your actual login function
								>
									Continue with Facebook
							</Button>
							</Box> */}
						</form>
					</CardContent>
				</Card>
				<div
					className="absolute"
					style={{ transform: "translateY(50%)" }} // Move up by 20px
				>
					<Typography style={{ color: "grey" }}>
						v{packageInfo.version}
					</Typography>
				</div>
			</Box>
		</Container>
	);
};

export default Login;
