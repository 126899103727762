import { Button, Card, TextField, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MarkdownEditor from "../../components/Markdown/MarkdownEditor";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import QuestionForm from "./QuestionForm";
import { Save } from "@mui/icons-material";
import trainerApi from "../../api/trainer";
import { LoadingButton } from "@mui/lab";
import { showError, showSuccess } from "../../App";

export default function EditQuestion() {
	const navigate = useNavigate();
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const [id, setId] = useState(null);
	const [data, setData] = useState({
		question: "",
		answer: "",
		url: "",
	});
	useEffect(() => {
		setId(location.hash.substring(1));
	}, [location]);

	const fetchData = async () => {
		const res = await trainerApi.getQuestionById(id);
		if (res.success) {
			setData(res.data);
		} else {
			console.log("Error:", res.error);
		}
	};

	useEffect(() => {
		if (id) {
			fetchData();
		}
	}, [id]);

	const handleEdit = async () => {
		// edit question
		setLoading(true);
		if (data.question === "" || data.answer === "") {
			showError("Question or Answer cannot be empty");
			setLoading(false);
			return;
		}
		const res = await trainerApi.updateQuestionById(id, data);
		if (res.success) {
			showSuccess("Changes saved successfully");
			// console.log("Saved:", id, data);
			// navigate("/trainer");
			setLoading(false);
		} else {
			showError("Can't save changes");
			setLoading(false);
		}
	};
	return (
		<QuestionForm
			value={data}
			setValue={setData}
			title={"Edit Question"}
			onClick={handleEdit}
			loading={loading}
		/>
	);
}
