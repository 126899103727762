import Api from "./base";

class TrainerApi extends Api {
	getQuestionById = async (id) => {
		return await this.get(`/qatrain/${id}`);
	};
	getQuestions = async () => {
		return await this.get(`/qatrain`);
	};
	updateQuestionById = async (id, data) => {
		return await this.put(`/qatrain/${id}`, data);
	};
	createQuestion = async (data) => {
		return await this.post(`/qatrain`, data);
	};
	deleteQuestionById = async (id) => {
		return await this.delete(`/qatrain/${id}`);
	};
	trainQuestion = async (id) => {
		return await this.get(`/qatrain/${id}/train`);
	};
}

const trainerApi = new TrainerApi();
export default trainerApi;
