import React, { createContext, useMemo, useState } from "react";
import {
	StyledEngineProvider,
	ThemeProvider,
	createTheme,
} from "@mui/material/styles";

export const ThemeContext = createContext({
	toggleMode: () => {},
});

export const ThemeContextProvider = ({ children }) => {
	const [mode, setMode] = useState(localStorage.getItem("theme") || "light");

	const toggleMode = () => {
		console.log("toggleMode");
		const oldTheme = localStorage.getItem("theme");
		const newTheme = oldTheme === "light" ? "dark" : "light";
		localStorage.setItem("theme", newTheme);
		setMode(newTheme);
		if (newTheme === "dark") {
			document.documentElement.classList.add("dark");
		} else {
			document.documentElement.classList.remove("dark");
		}
	};

	const theme = useMemo(
		() =>
			createTheme({
				palette: {
					mode,
				},
			}),
		[mode]
	);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeContext.Provider value={{ toggleMode }}>
				<ThemeProvider theme={theme}>{children}</ThemeProvider>
			</ThemeContext.Provider>
		</StyledEngineProvider>
	);
};
