import React, { useState, useEffect, useRef } from "react";
import AssistantChatLong from "./AssistantChatLong";
import UserChatLong from "./UserChatLong";
import axios from "axios";
import { IoSendSharp } from "react-icons/io5";
// import Sidebar from "../Components/SideBar";
import config from "../../config";
import { Box } from "@mui/material";
const API_URL = "https://penguapi.priyolab.net";
const USER_ID = "85025d36-d4f2-452c-b3cc-9675ab4be51a";

const Conversation = ({ chat, message_id, feedback }) => {
	const [conversation, setConversation] = useState([
		{
			role: "assistant",
			content: {
				content: `Hello. Please tell me how I can help you`,
				id: null,
			},
		},
	]);

	useEffect(() => {
		if (chat) {
			setConversation(
				chat.messages
					.reduce(
						(acc, curr, idx, src) => {
							// If the condition is met or we've already decided to stop adding items, return the accumulator as is
							if (
								acc.stop ||
								(idx > 1 && src[idx - 2].id === message_id)
							) {
								acc.stop = true; // Set a flag to stop adding items
								return acc;
							}
							// Otherwise, add the current item to the result array
							acc.result.push(curr);
							return acc;
						},
						{
							result: [
								{
									sender: "assistant",
									content: `Hello. Please tell me how I can help you`,
								},
							],
							stop: false,
						}
					)
					.result.concat([
						// Add your new message here
						{
							sender: "user",
							content: "<u>Feedback:</u> " + feedback,
						},
					])
			);
			console.log(chat.messages);
		}
	}, [chat]);

	return (
		<>
			{/* <Sidebar /> */}
			<div className="w-full md:w-4/5 rounded-xl">
				<div className="no-scrollbar top-0 flex p-2 flex-col w-full overflow-y-scroll no-scrollbar">
					{conversation.map((a, idx) => {
						if (a.sender === "assistant") {
							return (
								<AssistantChatLong
									key={idx}
									message={a}
									avatar="https://i.postimg.cc/yNny3NKs/3.png"
									flag={idx === conversation.length - 1}
								/>
							);
						} else if (a.sender === "user") {
							return (
								<UserChatLong
									key={idx}
									message={a}
									avatar="https://i.postimg.cc/s23J2htT/4.png"
									flag={idx === conversation.length - 1}
								/>
							);
						}
					})}
				</div>
			</div>
		</>
	);
};

export default Conversation;
