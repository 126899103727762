import Chatter from "../components/Chat/Chatter";
import {
	Box,
	Button,
	Card,
	Link,
	Skeleton,
	TextField,
	Typography,
} from "@mui/material";
import config from "../config.json";

export default function Home() {
	return (
		<Box
			className="p-5 mx-auto flex items-center flex-col gap-10 w-full justify-center"
			sx={{
				minHeight: `calc(100vh - ${config.topbarHeight}px)`,
			}}
		>
			<Chatter />
		</Box>
	);
}
