import React, { useState, useEffect, useRef } from "react";
import AssistantChatLong from "./AssistantChatLong";
import UserChatLong from "./UserChatLong";
import axios from "axios";
import { IoSendSharp } from "react-icons/io5";
// import Sidebar from "../Components/SideBar";
import config from "../../config";
import { Box } from "@mui/material";
const API_URL = "https://penguapi.priyolab.net";
const USER_ID = "85025d36-d4f2-452c-b3cc-9675ab4be51a";

const Chatter = () => {
	const bottomRef = useRef();
	const [currentUserChat, setCurrentUserChat] = useState(null);
	const [generatingChat, setGeneratingChat] = useState(false);
	const [chatId, setChatId] = useState(null); // State to store chat ID
	const [conversation, setConversation] = useState([
		{
			role: "assistant",
			content: {
				content: `Hello. Please tell me how I can help you`,
				id: null,
			},
		},
	]);

	const createChat = async (message) => {
		try {
			const response = await axios.post(`${API_URL}/api/v1/chats`, {
				user_id: USER_ID, // Replace with actual user ID
				first_message: message,
			});
			console.log(response.data);
			setChatId(response.data.id); // Save chat ID

			const agentConvBody = {
				role: "assistant",
				content: response.data.response,
			};

			setConversation((conversation) => {
				const updatedConversation = [...conversation];
				const lastUserMessageIndex = updatedConversation
					.slice()
					.reverse()
					.findIndex((msg) => msg.role === "user");

				if (lastUserMessageIndex !== -1) {
					const actualIndex =
						updatedConversation.length - 1 - lastUserMessageIndex;
					updatedConversation[actualIndex].content =
						response.data.query;
				}

				return [...updatedConversation, agentConvBody];
			});
		} catch (error) {
			console.error("Error creating chat:", error);
		}
	};

	const updateChat = async (message) => {
		try {
			const response = await axios.put(
				`${API_URL}/api/v1/chats/${chatId}`,
				{
					content: message,
				}
			);
			console.log(response.data);
			const agentConvBody = {
				role: "assistant",
				content: response.data.response,
			};

			setConversation((conversation) => {
				const updatedConversation = [...conversation];
				const lastUserMessageIndex = updatedConversation
					.slice()
					.reverse()
					.findIndex((msg) => msg.role === "user");

				if (lastUserMessageIndex !== -1) {
					const actualIndex =
						updatedConversation.length - 1 - lastUserMessageIndex;
					updatedConversation[actualIndex].content =
						response.data.query;
				}

				return [...updatedConversation, agentConvBody];
			});
		} catch (error) {
			console.error("Error updating chat:", error);
		}
	};

	const createIssue = async (feedback, message) => {
		const messageIndex = conversation.findIndex(
			(msg) => msg.content.id === message.id
		);
		const previousUserMessage = conversation
			.slice(0, messageIndex)
			.reverse()
			.find((msg) => msg.role === "user");

		const previousMessageContent = previousUserMessage
			? previousUserMessage.content
			: { id: null, content: "" };

		try {
			console.log({
				user_id: USER_ID,
				chat_id: chatId,
				message_id: previousMessageContent.id,
				message_content: previousMessageContent.content,
				feedback: feedback,
			});
			const response = await axios.post(`${API_URL}/api/v1/issues`, {
				user_id: USER_ID,
				chat_id: chatId,
				message_id: previousMessageContent.id,
				message_content: previousMessageContent.content,
				feedback: feedback,
			});
			console.log("Issue created:", response.data);
		} catch (error) {
			console.error("Error creating issue:", error);
		}
	};

	const handleCreateIssue = (feedback, message) => {
		createIssue(feedback, message);
	};

	const createConversation = async () => {
		if (generatingChat) {
			return;
		}
		const userConvBody = {
			role: "user",
			content: {
				content: currentUserChat,
				id: null,
			},
		};
		setConversation((conversation) => [...conversation, userConvBody]);

		setGeneratingChat(true);
		setCurrentUserChat("");

		if (!chatId) {
			await createChat(currentUserChat);
		} else {
			console.log(currentUserChat);
			await updateChat(currentUserChat);
		}

		setGeneratingChat(false);
	};

	useEffect(() => {
		if (bottomRef.current) {
			bottomRef.current.scrollIntoView();
		}
	}, [conversation]);

	return (
		<>
			{/* <Sidebar /> */}
			<div className="relative w-full md:w-4/5 min-h-[600px] md:min-h-[700px] no-scrollbar border rounded-xl shadow-md bg-gray-800 border-gray-700 ">
				<div className="absolute no-scrollbar pb-20 top-0 flex p-2 flex-col w-full max-h-[600px] overflow-y-scroll no-scrollbar">
					{conversation.map((a, idx) => {
						if (a.role === "assistant") {
							return (
								<AssistantChatLong
									key={idx}
									message={a.content}
									avatar="https://i.postimg.cc/yNny3NKs/3.png"
									onCreateIssue={handleCreateIssue}
								/>
							);
						} else if (a.role === "user") {
							return (
								<UserChatLong
									key={idx}
									message={a.content}
									avatar="https://i.postimg.cc/s23J2htT/4.png"
								/>
							);
						}
					})}
					{generatingChat && (
						<AssistantChatLong
							message={{
								content: "Hmmmmmm.......Let me think.....",
								id: null,
							}}
							avatar="https://i.postimg.cc/yNny3NKs/3.png"
							onCreateIssue={handleCreateIssue}
						/>
					)}
					<div ref={bottomRef}></div>
				</div>
				<div className="absolute flex items-center justify-between -bottom-1 bg-slate-700 w-full p-2 rounded-b-lg">
					<textarea
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								e.preventDefault();
								createConversation();
							}
						}}
						type="text"
						rows={1}
						className="block w-full md:w-full p-4 text-sm border rounded-lg bg-slate-800 border-gray-600 placeholder-gray-400 text-white focus:ring-primary-500 focus:border-primary-500"
						placeholder={"Enter your chat"}
						value={currentUserChat}
						onChange={(e) => setCurrentUserChat(e.target.value)}
					/>
					<div className="flex">
						<button
							onClick={createConversation}
							className="h-full mx-1 text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm p-4 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
						>
							<IoSendSharp />
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chatter;
