import React, { useState, useEffect, useRef } from "react";
import AssistantChatLong from "../Issues/AssistantChatLong";
import UserChatLong from "../Issues/UserChatLong";

const Conversation = ({ chat }) => {
	const [conversation, setConversation] = useState([
		{
			sender: "assistant",
			content: `Hello. Please tell me how I can help you`,
		},
	]);

	useEffect(() => {
		if (chat) {
			setConversation(
				chat.messages.reduce(
					(acc, curr, idx, src) => {
						// Otherwise, add the current item to the result array
						acc.result.push(curr);
						return acc;
					},
					{
						result: [
							{
								sender: "assistant",
								content: `Hello. Please tell me how I can help you`,
							},
						],
						stop: false,
					}
				).result
			);
		}
	}, [chat]);

	useEffect(() => {
		console.log("Conversation: ", conversation);
	}, [conversation]);

	return (
		<>
			{/* <Sidebar /> */}
			<div className="w-full md:w-4/5 rounded-xl">
				<div className="no-scrollbar top-0 flex p-2 flex-col w-full overflow-y-scroll no-scrollbar">
					{conversation?.map((a, idx) => {
						if (a.sender === "assistant") {
							return (
								<AssistantChatLong
									key={idx}
									message={a}
									avatar="https://i.postimg.cc/yNny3NKs/3.png"
								/>
							);
						} else if (a.sender === "user") {
							return (
								<UserChatLong
									key={idx}
									message={a}
									avatar="https://i.postimg.cc/s23J2htT/4.png"
								/>
							);
						}
					})}
				</div>
			</div>
		</>
	);
};

export default Conversation;
