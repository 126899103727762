import React, { useState } from "react";
import MDEditor from "@uiw/react-md-editor";
import { MdReportProblem } from "react-icons/md";

export default function AssistantChatLong({ message, avatar, onCreateIssue }) {
	const [showModal, setShowModal] = useState(false);
	const [feedback, setFeedback] = useState("");

	const handleReportIssue = () => {
		setShowModal(true);
	};

	const handleSubmitFeedback = () => {
		onCreateIssue(feedback, message);
		setShowModal(false);
		setFeedback("");
	};

	return (
		<div className="flex flex-col my-4">
			<div className="flex items-start">
				<img
					className="w-12 h-12 p-1 mr-4 rounded-full bg-gray-200"
					src={avatar}
					alt="Assistant Avatar"
				/>
				<div className="max-w-[80%] p-4 border-2 rounded-lg shadow-md border-gray-600">
					<MDEditor.Markdown
						className="text-xs"
						source={message.content}
						style={{
							background: "transparent",
							fontFamily: "Poppins",
							color: "white",
							textAlign: "left",
						}}
					/>
					{message.id && (
						<button
							onClick={handleReportIssue}
							className="mt-2 flex items-center text-xs text-white bg-gray-600 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg p-2"
						>
							<MdReportProblem className="mr-1" /> Report Issue
						</button>
					)}
				</div>
			</div>
			{showModal && (
				<div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
					<div className="bg-gray-800 p-4 rounded-lg shadow-lg border border-gray-600">
						<h2 className="text-white mb-2">Report Issue</h2>
						<textarea
							className="w-full p-2 bg-gray-700 text-white rounded-lg mb-2"
							placeholder="Describe the issue..."
							value={feedback}
							onChange={(e) => setFeedback(e.target.value)}
						/>
						<div className="flex justify-end">
							<button
								onClick={handleSubmitFeedback}
								className="mr-2 text-white bg-blue-600 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg p-2"
							>
								Submit
							</button>
							<button
								onClick={() => setShowModal(false)}
								className="text-white bg-red-600 hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg p-2"
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
