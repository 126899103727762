import React from "react";
import MDEditor from "@uiw/react-md-editor";
export default function UserChatLong({ message, avatar }) {
	return (
		<div className="flex w-full justify-end my-2">
			<div
				href="#"
				class="max-w-[80%]  p-4 border-2 border-primary-400 text-white border rounded-lg shadow-md border-gray-700 "
			>
				<MDEditor.Markdown
					className="text-xs"
					source={message.content}
					style={{
						background: "transparent",
						color: "white",
						fontFamily: "Poppins",
						textAlign: "right",
					}}
				/>
			</div>
			<img
				className="w-12 h-12 p-1 ml-2 rounded-full bg-gray-200"
				src={avatar}
				alt=""
			/>
		</div>
	);
}
