import Api from "./base";

class UserApi extends Api {
	getChatById = async (id) => {
		return await this.get(`/chats/` + id);
	};
	getChatsByUser = async (id) => {
		return await this.get(`/chats/users/` + id);
	};
	getAllUsers = async () => {
		return await this.get(`/users`);
	};
}

const userApi = new UserApi();
export default userApi;
