import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAdd,
	faBoxTissue,
	faChartLine,
	faEarthAmericas,
	faEye,
	faGears,
	faHouse,
	faPen,
	faThumbTack,
	faUsers,
} from "@fortawesome/free-solid-svg-icons";

// Utility function to format breadcrumb text
function formatBreadcrumbText(text) {
	return text
		.split("-")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(" ");
}
function getIcon(value) {
	// return value === "Trainer" ? faPen : value == faHouse;
	// Switch case to return the correct icon based on the value
	switch (value) {
		case "edit":
			return faPen;
		case "users":
			return faUsers;
		case "add":
			return faAdd;
		case "trainer":
			return faGears;
		case "scraper":
			return faEarthAmericas;
		case "issues":
			return faBoxTissue;
		case "analytics":
			return faChartLine;
		case "preview":
			return faEye;
		default:
			return faHouse;
	}
}
export default function Breadcrumb() {
	const location = useLocation();
	const navigate = useNavigate(); // Use the useNavigate hook

	const handleClick = (event, to) => {
		event.preventDefault();
		console.log("Clicked");
		navigate(to); // Navigate to the clicked breadcrumb's link
	};

	const pathnames = location.pathname.split("/").filter((x) => x);

	const breadcrumbs = [
		pathnames.length === 0 ? (
			<Typography
				key="1"
				color="text.primary"
				className="flex items-center gap-2"
			>
				<FontAwesomeIcon icon={faHouse} />
				Home
			</Typography>
		) : (
			<Link
				underline="hover"
				key="1"
				color="inherit"
				href="/"
				onClick={(event) => handleClick(event, "/")}
				className="flex items-center gap-2"
			>
				<FontAwesomeIcon icon={faHouse} />
				Home
			</Link>
		),
		...pathnames.map((value, index) => {
			const last = index === pathnames.length - 1;
			const to = `/${pathnames.slice(0, index + 1).join("/")}`;
			const formattedValue = formatBreadcrumbText(value);

			return last ? (
				<Typography
					key={index + 2}
					color="text.primary"
					className="flex items-center gap-2"
				>
					<FontAwesomeIcon icon={getIcon(value)} />
					{formattedValue}
				</Typography>
			) : (
				<Link
					underline="hover"
					key={index + 2}
					color="inherit"
					href={to}
					onClick={(event) => handleClick(event, to)}
					className="flex items-center gap-2"
				>
					<FontAwesomeIcon icon={getIcon(value)} />
					{formattedValue}
				</Link>
			);
		}),
	];

	return (
		<Breadcrumbs
			separator={<NavigateNextIcon fontSize="small" />}
			aria-label="breadcrumb"
		>
			{breadcrumbs}
		</Breadcrumbs>
	);
}
