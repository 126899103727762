import { DarkMode, WbSunny } from "@mui/icons-material";
import { Avatar, IconButton } from "@mui/material";
import { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContextProvider";
import DrawerBar from "../Sidebars/DrawerBar";

export default function ToggleButton() {
	const { toggleMode } = useContext(ThemeContext);
	return (
		<IconButton
			onClick={() => {
				toggleMode();
			}}
			className="r-5 t-0 z-50"
			sx={{
				width: 40,
				height: 40,
			}}
		>
			<WbSunny
				style={{ fontSize: "1.75rem" }}
				className="absolute rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0 "
			/>
			<DarkMode
				style={{ fontSize: "1.75rem" }}
				className="absolute rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100"
			/>
			<span className="sr-only">Toggle theme</span>
		</IconButton>
	);
}
