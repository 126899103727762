import React, { useEffect, useState } from "react";
import MDEditor, { commands } from "@uiw/react-md-editor";
import "./styles.scss";

const MarkdownEditor = ({ colorMode, text, setText }) => {
	return (
		<div data-color-mode={colorMode}>
			<div className="wmde-markdown-var"> </div>
			<MDEditor
				// height={
				// 	85 + 35 * calculateNumberOfLines(text) + text.length / 2
				// }
				height={300}
				value={text}
				onChange={setText}
				style={{ borderRadius: "0 0 5px 5px" }}
				visibleDragbar={false}
				extraCommands={[]}
				// preview="edit"
				commands={[
					commands.bold,
					commands.italic,
					commands.strikethrough,
					commands.hr,
					commands.title,
					commands.divider,
					commands.link,
					commands.quote,
					commands.code,
					commands.codeBlock,
					commands.divider,
					commands.unorderedListCommand,
					commands.orderedListCommand,
					commands.checkedListCommand,
					// Add or remove commands as needed
				]}
			/>
		</div>
	);
};

export default MarkdownEditor;
