import Api from "./base";

class IssueApi extends Api {
	getIssues = async () => {
		return await this.get(`/issues`);
	};

	getIssueById = async (id) => {
		return await this.get(`/issues/${id}/chat`);
	};

	resolveIssue = async (id, response) => {
		return await this.put(`/issues/${id}`, {
			response,
			status: "resolved",
		});
	};

	deleteIssue = async (id) => {
		return await this.post(`/issues/${id}/delete`);
	};
}

const issueApi = new IssueApi();
export default issueApi;
